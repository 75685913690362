import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { fetch as crossFetch } from 'cross-fetch';
import React from 'react';

const httpLink = createHttpLink({
  uri: `${process.env.SPACESHIP_API_URL}/v0/external/query`,
  fetch: crossFetch,
});

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      // Make sure we always get latest state from API.
      fetchPolicy: 'cache-and-network',
      // Support partial responses in the case of errors.
      errorPolicy: 'all',
    },
  },
});

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
