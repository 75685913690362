/*
  Import the fonts to load the font faces
*/
import 'typeface-ibm-plex-mono';
import 'typeface-inter';
import 'typeface-playfair-display';
import 'typeface-poppins';

import { themes } from '@spaceship-fspl/styles';
import { SegmentProvider } from '@spaceship-fspl/tracking';
import { ApiExternalProvider } from 'data/api-external';
import { Provider as ApolloProvider } from 'data/apollo';
import { AppProps } from 'next/app';
import React from 'react';
import { ThemeProvider } from 'styled-components';

// === START DEVELOPMENT ENVIRONMENT CODE ===
if (process.env.NODE_ENV === 'development') {
  // Ignore errors for known issues
  const originalErrorLogger = console.error;
  const ignoreList = [
    // @see https://github.com/vercel/next.js/issues/7322
    'Warning: Prop `%s` did not match. Server: %s Client: %s%s',
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customErrorLogger = (...data: any[]): void => {
    if (typeof data[0] === 'string' && ignoreList.includes(data[0])) {
      return;
    }
    originalErrorLogger(...data);
  };
  console.error = customErrorLogger;
}
// === END DEVELOPMENT ENVIRONMENT CODE ===

// === START REDIRECT /index.html to / CODE ===
/*
 This is a very very gross hack that will greatly affect performance,
 until we can get the web host to handle redirects for us
 */
if (typeof window !== 'undefined') {
  const url = window.location.pathname.replace(/\/index.html$/, '/');
  if (url !== window.location.pathname) {
    window.location.href = url;
  }
}
// === END REDIRECT /index.html to / CODE ===

export const MyApp: React.FC<React.PropsWithChildren<AppProps>> = ({
  Component,
  pageProps,
}) => {
  return (
    <SegmentProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? ''}>
      <ApolloProvider>
        <ApiExternalProvider>
          <ThemeProvider theme={themes.marketing}>
            <Component {...pageProps} />
          </ThemeProvider>
        </ApiExternalProvider>
      </ApolloProvider>
    </SegmentProvider>
  );
};

export default MyApp;
