import { Provider } from '@spaceship-fspl/data';
import { createFetch } from '@spaceship-fspl/fetch';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const apiExternalClient = createFetch({
  url: `${process.env.SPACESHIP_API_URL}/v0/external`,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  },
});

export const ApiExternalProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <QueryClientProvider client={queryClient}>
    <Provider fetch={apiExternalClient}>{children}</Provider>
  </QueryClientProvider>
);
